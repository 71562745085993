import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import $ from 'jquery'

/**
 * Context API
 */
import { useGlobalState } from '@store/hooks';

/**
 * Assets
 */
import './assets/styles/_index.scss';
import Logo from './assets/img/logo.svg';
import Account from './assets/img/user-icon.svg';
import Search from './assets/img/search-icon.svg';

/**
 * Components
 */
import Navigation from '@components/navigation/navigation';

const Header = (props, {className}) => {
  const [active, setActive] = useState(false);
  const navigationToggle = () => setActive(!active);
  const headerEle = useRef();
  const { services: modalServices } = useGlobalState('modals');
  const modalOpen = (event) => {
    event.preventDefault();
    $("html").addClass("bg-scroll-disable");
    modalServices.modalToggle({name: 'property_search', active: true});
  }

  const handleOnScroll = () =>  window.pageYOffset  > 10 || document.documentElement.scrollTop > 10 ? headerEle.current.classList.add('is-scrolled') : headerEle.current.classList.remove('is-scrolled');

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [])

  const url = typeof window !== 'undefined' ? window.location.href : ''



  return (
    <header className={classNames('header', className)} ref={headerEle}>
      <Container fluid={true}>
        <Row>
          <Col className="header__left">
            <div className="logo">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </Col>
          <Col className="header__right col col-auto">
            <Nav className="utils-nav">


                {props.data.strapiGlobalConfig.Top_Navigation_Menu.Menu.map((Menu, index) => {

                    var menu_item = Menu.URL

                    if(url.indexOf(menu_item) > -1) {
                      var active_class = "d-none d-lg-block active";
                    }
                    else
                    {
                      var active_class = "d-none d-lg-block";
                    }
                  return (
                  <>
                  {Menu.URL !== "#search" &&
                    <Nav.Item className={active_class}>
                      <Link to={`/${Menu.URL}`} className="nav-link"><span className="item">{Menu.Label}</span></Link>
                    </Nav.Item>
                  }
                  {Menu.URL === "#search" &&
                    <Nav.Item className={active_class}>
                      <Nav.Link href="/" onClick={modalOpen}>{Menu.Label}</Nav.Link>
                    </Nav.Item>
                  }
                  </>
                  )
                })}

              <Nav.Item className="d-lg-none">
                <Nav.Link href="/" onClick={modalOpen}><Search/></Nav.Link>
              </Nav.Item>
              {/*<Nav.Item>
                <Nav.Link href="/"><Account/></Nav.Link>
              </Nav.Item>*/}
            </Nav>
            <Navigation active={active} navigationToggle={navigationToggle} />
          </Col>
        </Row>
      </Container>
    </header>
  );
}


export default function HolroydsHeader() {
  return (
    <StaticQuery
      query={graphql`
      query {

        strapiGlobalConfig {
          Top_Navigation_Menu {
            Menu {
              Label
              URL
            }
          }
        }

      }
    `}
      render={data => <Header data={data} />}
    />
  )
}