import { useStaticQuery, graphql } from "gatsby";

import {
  AREAS,
  AREAS_NAMES_ALL,
  DEFAULT_AREA,
  AREA_GUIDE_AREAS,
  AREA_GUIDE_URLS,
  AREA_GUIDES_PAGE_URL
} from "@components/common/site/constants";
import {
  pTypeBase,
  ACTION_TYPES,
  detailsBase,
  CURRENCY
} from "@components/common/property/constants";
import {
  toTitleCase,
  getUrlLastPart
} from "@components/common/site/utils";

let _AREAS = AREAS;
let _AREAS_NAMES_ALL = AREAS_NAMES_ALL;
let _DEFAULT_AREA = DEFAULT_AREA;
let _AREA_GUIDE_AREAS = AREA_GUIDE_AREAS;
let _AREA_GUIDE_URLS = AREA_GUIDE_URLS;
let _AREA_GUIDES_PAGE_URL = AREA_GUIDES_PAGE_URL;
let _CURRENCY = CURRENCY;

export const getPriceStr = ( price ) => {
    let priceStr = '';
    if (price.min && price.max) {
        priceStr = " between " + _CURRENCY + addCommas(price.min) + " and " + _CURRENCY + addCommas(price.max);
    } else if (price.max) {
        priceStr = " under " + _CURRENCY + addCommas(price.max);
    } else if (price.min) {
        priceStr = " over " + _CURRENCY + addCommas(price.min);
    }
    return priceStr;
}

function addCommas(num) {
  // console.log('num : ' + num);
  var str = num.toString().split('.');
  // console.log('str, ' + str);
  if (str[0].length >= 4) {
      // add comma every 3 digits before decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location) => {
  if (location) {
      var query = '';
      let pathUri_main = location.split(pTypeBase[pType].slice(0, -1))
      var areaVal = ''
      var bedVal = ''
      var minpriceVal = ''
      var maxpriceVal = ''
      var typeVal = ''
      if( pathUri_main[1] ) { // following could be regexp
        let pathUri = pathUri_main[1].split('/');
        //lets loop
        for(let vi = 1; vi <= pathUri.length; vi++ ) {
          // check for area
          if( typeof pathUri[vi] === "undefined" ) {continue;}
          if ( pathUri[vi].indexOf("in-") >= 0 ) {
            // its area
            areaVal = pathUri[vi].replace("in-","").replace("-"," ");
          }
          if ( pathUri[vi].indexOf("-and-more-") >= 0 ) {
            // its bedrooms
            bedVal = pathUri[vi].replace("-and-more-bedrooms","")
          }
          if ( pathUri[vi].indexOf("studio") >= 0 ) {
            // its studio bedroom
            bedVal = 0
          }
          if ( pathUri[vi].indexOf("between-") >= 0 || 
               pathUri[vi].indexOf("over-") >= 0 || 
               pathUri[vi].indexOf("under-") >= 0
             ) {
            // its price
            let priceFilt1 = pathUri[vi].split('over-');
            if( priceFilt1[1] ) {
              minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split('under-');
            if( priceFilt2[1] ) {
              maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split('between-');
            if( priceFilt3[1] ) {
              let priceFilt4 = priceFilt3[1].split('-and-');
              minpriceVal = priceFilt4[0]
              maxpriceVal = priceFilt4[1]
            }
          }
          let priceFilt5 = pathUri[vi].split('type-');
          if( priceFilt5[1] ) {
            typeVal = priceFilt5[1]
          }
        }
      }

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {
        areaVal = "West Yorkshire"; // TODO
    }

    var areaAlias = areaVal.replace(' ', '-');

    return {
      pType: pType,
      areas: areaVal,
      areas_alias: areaAlias,
      bedrooms: bedVal,
      type: typeVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      }
    }
  }
}
  
// TODO: hard coded details to be removed/replaced with common variables
export const getPropertyLink = (item) => {
  let baseLink = detailsBase[item.Property_Type];

  let uriStr = baseLink;

  if ( item.Bedrooms ) {
    if(item.Bedrooms === 0)
      uriStr += "studio-"
    else if(item.Bedrooms === 1)
      uriStr += `${item.Bedrooms}-bedroom-`
    else if(item.Bedrooms > 1)
      uriStr += `${item.Bedrooms}-bedrooms-`
  }
  if ( item.Building_Type ) {
    uriStr += `${item.Building_Type.toLowerCase()}-`
  }

  if (item.Property_Type.match(/Sales|sales/g))
      uriStr += "for-sale-"
  if (item.Property_Type.match(/Rental|rental/g))
      uriStr += "to-rent-"

  if (item.Area) {
      uriStr += `in-${item.Area.slug}`
  }
  if (item.id) {
      uriStr += `-${item.id}`
  }

  uriStr   = uriStr .replace("Properties_", "")
  return uriStr;
}
    
// TODO: hard coded details to be removed/replaced with common variables
export const propertyH1 = (params) => {
  // console.log('params : ' + params);

  const {areas, bedrooms, pType, type, price, extra} = params;
  // console.log('areas : ' + areas);

  let h1 = "";
  let building = "";

  if ('Commercial' === pType) { // TODO
    // Commercial Property [action] in [default search area] for Sale and Rent
    building = "Commercial ";

    if (type)
      building += ` ${type} `;
    else
      building += ` Property `;

    h1 += `${building} in `;

    if (areas) {
      h1 += areas;
    }

    h1 += ` for sale and rent`;

  } else {
    let building = "Property";
    if (bedrooms)
      building = "property";

    if (bedrooms) {
      let bedPlus = extra.bedPlus ? '+ ' : '';
      h1 += bedrooms + bedPlus + " bedroom ";
    }
    h1 += building;
    h1 += ACTION_TYPES[pType];

    if (areas) {
      let _areas = areas;
      _areas = _areas.replace("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas;
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }
  }

  // console.log("H1 title => ", h1);
  return h1;
}

export const getArea = (area) => {
  let _area = area || '';
  // console.log(_area);
  let _areaName = '';
  let _areaAlias = '';
  if ( _area ) {
    _areaName = _area;
    _areaName = _areaName.split(',').shift(); // if "Bradford, West Yorkshire", use "Bradford"
    _areaName = toTitleCase( _areaName.toLowerCase() );
    // console.log('_areaName : ' + _areaName);
    _areaAlias = _areaName.toLowerCase().replace(/\s+/g, '-');
    // console.log('_areaAlias : ' + _areaAlias);
  } else {
    _areaName = DEFAULT_AREA.name;
    _areaAlias = DEFAULT_AREA.alias;
  }
  _area = {name: _areaName, alias: _areaAlias};
  // console.log(_area);
  return _area;
}

export const CheckArea = (area) => {
  let _area = area || '';
  // console.log('_area : ' + _area);
  const data = useStaticQuery(graphql`
    query {
      allStrapiProperties {
        edges {
          node {
            display_address
          }
        }
      }
    }
  `);
  let validArea = {};
  let orgArea = _area;
  let orgAreaName = '';
  if ( orgArea ) {
    orgAreaName = orgArea.name.trim().toLowerCase();
    validArea.name = orgArea.name;
    validArea.alias = orgArea.alias;
  }
  // console.log('orgAreaName : ' + orgAreaName);
  let items = data.allStrapiProperties.edges;
  let itemsLength = items.length;
  // console.log('itemsLength : ' + itemsLength);
  for (let i = 0; i < itemsLength; i++) {
    let item = items[i];
    let displayAddress = item.node.display_address;
    let areaNames = displayAddress.split(',');
    let areaNamesLength = areaNames.length;
    let validAreaFlag = false;
    for (let j = 0; j < areaNamesLength; j++) {
      let areaName = areaNames[j];
      let tmpArea = areaName.trim().toLowerCase();
      // console.log('tmpArea : ' + tmpArea);
      if ( tmpArea && (tmpArea.indexOf(orgAreaName) > -1) ) {
        validArea.name = areaName;
        validArea.alias = tmpArea.replace(/\s+/g, '-');
        validAreaFlag = true;
        break;
      }
    }
    if (validAreaFlag) {
      break;
    }
  }
  // console.log('validArea.name ' + validArea.name);
  // console.log('validArea.alias ' + validArea.alias);
  return validArea;
}

export const getAreaGuideArea = () => {
  let areaGuideArea = '';
  let urlLastPart = getUrlLastPart();
  // urlLastPart = urlLastPart+'-estate-agents'; // for testing purpose
  // console.log('urlLastPart : ' + urlLastPart);
  _AREAS_NAMES_ALL.map((area) => {
    let areaName = _AREAS[area].name;
    let areaAlias = _AREAS[area].alias;
    // console.log('areaAlias : ' + areaAlias);
    if ( urlLastPart === areaAlias ) {
      // console.log('if');
      areaGuideArea = areaName;
    } else if ( urlLastPart && urlLastPart.indexOf(areaAlias) > -1 ) {
      // console.log('else if');
      // console.log('urlLastPart : ' + urlLastPart);
      let _urlLastPart = urlLastPart.replace(areaAlias, ''); // bingley-estate-agents => -estate-agents
      // console.log('_urlLastPart : ' + _urlLastPart);
      areaGuideArea = urlLastPart.replace(_urlLastPart, ''); // bingley-estate-agents => bingley
    }
  });
  // console.log('areaGuideArea : ' + areaGuideArea);
  return areaGuideArea;
}

export const areaGuideUrl = (areaGuideArea) => {
  let _areaGuideUrl = {};
  let _areaGuideArea = areaGuideArea || '';
  // console.log('_areaGuideArea : ' + _areaGuideArea);
  _AREA_GUIDE_AREAS.map((area) => {
    let areaName = _AREA_GUIDE_URLS[area].name;
    let areaAlias = _AREA_GUIDE_URLS[area].alias;
    // console.log('areaName : ' + areaName);
    // console.log('areaAlias : ' + areaAlias);
    let tmpAreaGuideArea = _areaGuideArea.toLowerCase();
    let tmpAreaName = areaName.toLowerCase();
    // console.log('tmpAreaGuideArea : ' + tmpAreaGuideArea);
    // console.log('tmpAreaName : ' + tmpAreaName);
    if ( tmpAreaGuideArea && (tmpAreaGuideArea === tmpAreaName) ) {
      // console.log('if');
      _areaGuideUrl.name = areaName + ' Area Guide'; // TODO: remove/replace hard coded value
      _areaGuideUrl.alias = areaAlias;
    }
  });
  // console.log(typeof _areaGuideUrl);
  if ( (Object.keys(_areaGuideUrl).length === 0) || (_areaGuideArea === '') ) {
    _areaGuideUrl.name = _DEFAULT_AREA.name + ' Area Guides'; // TODO: remove/replace hard coded value
    _areaGuideUrl.alias = _AREA_GUIDES_PAGE_URL.alias;
  }
  return _areaGuideUrl;
}

export const populateSearchState = (params) => {
  let state = {};
  state.page = params.page || 1;
  state.query = params.areaAlias || '';
  state.range = params.range || {};
  state.range.bedroom = params.bed || {};
  state.range.bedroom.min = params.minBeds || '';
  state.range.bedroom.max = params.maxBeds || '';
  state.range.price = params.price || {};
  state.range.price.min = params.minPrice || '';
  state.range.price.max = params.maxPrice || '';
  return state;
}

export const populateSearchData = (params) => {
  let data = {};
  data.areas = params.areaName;
  data.bedrooms = params.beds || '';
  data.pType = params.pType || '';
  data.type = '';
  data.price = {};
  data.price.min = params.minPrice || '';
  data.price.max = params.maxPrice || '';
  data.extra = {};
  data.extra.bedPlus = params.bedPlus ?? false;
  data.extra.areasTitleCase = params.areasTitleCase ?? true;
  return data;
}