import React from 'react';
import classNames from 'classnames';

/**
 * Assets
 */
import './assets/styles/_index.scss';

const MainContent = ({children, className}) => (
  <div className={classNames('main-content', className)}>
    {children}
  </div>
)

export default MainContent
