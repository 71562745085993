// TODO: not used details to be checked & removed

// export const CURRENCY = '&pound;'; // this is not working
export const CURRENCY = '£';

export const REFINE_BUILDING_TYPES = ["Office", "Premises", "Restaurants"];

export const SEARCH_TYPES = {
    SALES: "Sales",
    RENTALS: "Rentals",
    NEW_HOMES: "Newhomes",
    COMMERCIAL: "Commercial",
    COMMERCIAL_SALES: "Commercial_Sales", 
    COMMERCIAL_RENTALS: "Commercial_Rentals"
}
  
export const ACTION_TYPES = {
    Sales: " for sale in ",
    Rentals: " to rent in ",
    Newhomes: " for sale in ",
    Commercial: " for sale and rent in ",
    Commercial_Sales: " for sale in ",
    Commercial_Rentals: " to rent in "
}

export const pTypeBase = {
    Sales: "/property/sales/",
    Rentals: "/property/rentals/",
    Commercial: "/property/commercial/",
    Commercial_Sales: "/property/commercial/",
    Commercial_Rentals: "/property/commercial/",
    NewHomes: "/property/new-homes-and-developments/"
}

/*
export const detailsBase = {
    Sales: "/property-for-sale/",
    Rentals: "/property-to-rent/",
    Commercial: "/property/commercial/",
    Commercial_Sales: "/commercial-property-sale/",
    Commercial_Rentals: "/commercial-property-rental/",
    NewHomes: "/property/new-homes-and-developments/"
}
*/

export const detailsBase = {
    Sales: "/property-detail/",
    Rentals: "/property-detail/"
}
    
export const REFINE_AREAS = {
    "defaultarea" : "West Yorkshire"
}