import React from "react";

import { addLinksToArray } from "@components/common/site/utils";
import {
    areaGuideUrl,
    getAreaGuideArea
} from "@components/common/property/utils";
import {
    VALUATION_PAGE_URL,
    CONTACT_PAGE_URL,
    ABOUT_HOLROYDS_PAGE_URL,
    MEET_THE_TEAM_PAGE_URL,
    PROPERTY_SERVICES_PAGE_URL,
    TESTIMONIALS_PAGE_URL,
    LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL,
    LATEST_NEWS_PAGE_URL,
    PROPERTY_SERVICES_MORTGAGES_PAGE_URL
} from "@components/common/site/constants";
import { SEARCH_TYPES } from "@components/common/property/constants";

import { bedRoomsUrls } from "../../utils";
import PopularSearchAccordion from "../../accordion";

const PopularSearchDynamicAreaGuideDetails = (props) => {
    
    let _props = {...props};

    let _areaGuideArea = getAreaGuideArea() || '';

    let _bedRoomsSalesUrls = bedRoomsUrls({searchType: SEARCH_TYPES.SALES, area: _areaGuideArea});
    let _bedRoomsRentalsUrls = bedRoomsUrls({searchType: SEARCH_TYPES.RENTALS, area: _areaGuideArea});

    let _areaGuideUrl = areaGuideUrl(_areaGuideArea);

    let col1Links = [];
    addLinksToArray(_bedRoomsSalesUrls, col1Links);
    
    let col2Links = [];
    addLinksToArray(_bedRoomsRentalsUrls, col2Links);

    let col3Links = [];
    col3Links.push(VALUATION_PAGE_URL);
    col3Links.push(CONTACT_PAGE_URL);
    col3Links.push(ABOUT_HOLROYDS_PAGE_URL);
    col3Links.push(_areaGuideUrl);
    col3Links.push(MEET_THE_TEAM_PAGE_URL);

    let col4Links = [];
    col4Links.push(PROPERTY_SERVICES_PAGE_URL);
    col4Links.push(TESTIMONIALS_PAGE_URL);
    col4Links.push(LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL);
    col4Links.push(LATEST_NEWS_PAGE_URL);
    col4Links.push(PROPERTY_SERVICES_MORTGAGES_PAGE_URL);

    _props.col1Links = col1Links;
    _props.col2Links = col2Links;
    _props.col3Links = col3Links;
    _props.col4Links = col4Links;

    return (
        <div class="component-PopularSearchDynamicAreaGuideDetails">
            <PopularSearchAccordion {..._props} />
        </div>
    )
}

export default PopularSearchDynamicAreaGuideDetails;