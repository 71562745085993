import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const Holder = ({maxWidth, children, mobile, className}) => {
  const [active, setActive] = useState(true);

  useEffect(() => {
    if(window.innerWidth < process.env.MOBILE_VIEW && !mobile){
      setActive(false);
    }
  }, [mobile])

  return (
    <div className={classNames('holder', className)} style={active ? {maxWidth: maxWidth} : {}}>
      {children}
    </div>
  )
}

export default Holder
