import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import classNames from 'classnames';

const SearchFormEdit = ({className}) => {
  const [form, setForm] = useState({
    location: '',
    min_price: '',
    max_price: '',
    bedrooms: 0
  })
  const handleChange = (event) => {
    const value =  event.target.value;
    const name = event.target.getAttribute('name');
    setForm({
      ...form,
      [name]: value
    })
  }
  return (
    <div className={classNames('search-form', className)}>
      <h3 className="text-center">Edit Your Search</h3>
      <Form>
        <Form.Group>
          <div className="custom-float">
            <Form.Label>Location</Form.Label>
            <Form.Control 
              id="location"
              name="location"
              value={form.location}
              onChange={handleChange}
            />
          </div>
        </Form.Group>
        <Form.Group>
          <div className="custom-float custom-float--select">
            <Form.Label>Minimum Price</Form.Label>
            <Form.Control 
              as="select" 
              name="min_price" 
              value={form.min_price}
              id="min_price"
              onChange={handleChange}
            >
              <option value="" aria-label="empty"></option>
              <option value="£10,000">£10,000</option>
              <option value="£20,000">£20,000</option>
              <option value="£30,000">£30,000</option>
              <option value="£40,000">£40,000</option>
              <option value="£50,000">£50,000</option>
            </Form.Control>
          </div>
        </Form.Group>
        <Form.Group>
          <div className="custom-float custom-float--select">
            <Form.Label>Maximum Price</Form.Label>
            <Form.Control 
              as="select" 
              name="max_price" 
              value={form.max_price}
              id="max-price"
              onChange={handleChange}
            >
            <option value="" aria-label="empty"></option>
              <option value="£10,000">£10,000</option>
              <option value="£20,000">£20,000</option>
              <option value="£30,000">£30,000</option>
              <option value="£40,000">£40,000</option>
              <option value="£50,000">£50,000</option>
            </Form.Control>
          </div>
        </Form.Group>
        <Form.Group>
          <div className="custom-float custom-float--select">
            <Form.Label>Bedrooms</Form.Label>
            <Form.Control 
              as="select" 
              name="bedrooms" 
              value={form.bedrooms}
              id="bedrooms"
              onChange={handleChange}
            >
              <option value="" aria-label="empty"></option>
              <option value="1">1+ Bedrooms</option>
              <option value="2">2+ Bedrooms</option>
              <option value="3">3+ Bedrooms</option>
              <option value="4">4+ Bedrooms</option>
              <option value="5">5+ Bedrooms</option>
            </Form.Control>
          </div>
        </Form.Group>
        <Button variant="primary" type="submit">
          Update
        </Button>
      </Form>
    </div>
  )
}

export default SearchFormEdit;
