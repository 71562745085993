import React from "react";

import { SEARCH_TYPES } from "@components/common/property/constants";

import PopularSearchStaticDefault from '../../static/default';
import PopularSearchDynamicPropertiesResultsSales from './sales';
import PopularSearchDynamicPropertiesResultsRentals from './rentals';

const PopularSearchDynamicPropertiesResultsDefault = (props) => {

    let _props = {...props};

    let isDefaultSearchArea = true;
    let areaFilter = false;
    let bedsFilter = false;
    let priceFilter = false;

    let searchType = _props.searchType || '';
    // console.log('searchType : ' + searchType);

    let searchState = _props.searchState || {};
    // console.log('searchState : ' + searchState);
    // console.log( Object.keys(searchState) );
    // console.log( Object.values(searchState) );

    let query = searchState.query || '';
    // console.log('query : ' + query);

    let searchLocation = '';
    let building = '';
    let bedroom = '';
    let minBeds = '';
    let maxBeds = '';
    let price = '';
    let minPrice = '';
    let maxPrice = '';

    if (query) {
        isDefaultSearchArea = false;
        searchLocation = query;
        areaFilter = true;
    }

    let range = searchState.range || '';
    // console.log(range);
    
    if ( range ) {
        bedroom = range.bedroom || '';
        // console.log(bedroom);
        if (bedroom) {
            minBeds = bedroom.min || '';
            // console.log('minBeds : ' + minBeds);
            maxBeds = bedroom.max || '';
            // console.log('maxBeds : ' + maxBeds);
            if ( minBeds || maxBeds ) {
                bedsFilter = true;
            }
        }
        price = range.price || '';
        // console.log(price);
        if (price) {
            minPrice = price.min || '';
            // console.log('minPrice : ' + minPrice);
            maxPrice = price.max || '';
            // console.log('maxPrice : ' + maxPrice);
            if ( minPrice || maxPrice ) {
                priceFilter = true;
            }
        }
    }

    _props.isDefaultSearchArea = isDefaultSearchArea;
    _props.areaFilter = areaFilter;
    _props.bedsFilter = bedsFilter;
    _props.priceFilter = priceFilter;

    _props.searchLocation = searchLocation;
    _props.building = building;
    _props.bedroom = bedroom;
    _props.minBeds = minBeds;
    _props.maxBeds = maxBeds;
    _props.price = price;
    _props.minPrice = minPrice;
    _props.maxPrice = maxPrice;

    // console.log('isDefaultSearchArea : ' + isDefaultSearchArea);
    // console.log('areaFilter : ' + areaFilter);
    // console.log('bedsFilter : ' + bedsFilter);
    // console.log('priceFilter : ' + priceFilter);

    const renderPopularSearchItems = () => {
        switch (searchType) {
            case `${SEARCH_TYPES.SALES}`:
                return <PopularSearchDynamicPropertiesResultsSales {..._props} />;
            case `${SEARCH_TYPES.RENTALS}`:
                return <PopularSearchDynamicPropertiesResultsRentals {..._props} />;
            default:
                return <PopularSearchStaticDefault {..._props} />;
        }
    }

    return (
        <div class="component-PopularSearchDynamicPropertiesResultsDefault">
            {renderPopularSearchItems()}
        </div>
    )
}

export default PopularSearchDynamicPropertiesResultsDefault;