import React, { useState,Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { navigate } from "@reach/router"
import $ from "jquery"

const salepriceminrange = [
        {
       value: "50000",
       label: "£50,000"
    },
    { 
       value : "60000",
       label: "£60,000"
    },
    { 
       value : "70000",
       label: "£70,000"
    },
    { 
       value : "80000",
       label: "£80,000"
    },
    { 
       value : "90000",
       label: "£90,000"
    },
    { 
       value : "100000",
       label: "£100,000"
    },
    { 
       value : "120000",
       label: "£120,000"
    },
    { 
       value : "140000",
       label: "£140,000"
    },
    { 
       value : "160000",
       label: "£160,000"
    },
    { 
       value : "180000",
       label: "£180,000"
    },
    { 
       value : "200000",
       label: "£200,000"
    },
    { 
       value : "225000",
       label: "£225,000"
    },
    { 
       value : "275000",
       label: "£275,000"
    },
    { 
       value : "300000",
       label: "£300,000"
    },
    { 
       value : "325000",
       label: "£325,000"
    },
    { 
       value : "350000",
       label: "£350,000"
    },
    { 
       value : "375000",
       label: "£375,000"
    },
    { 
       value : "400000",
       label: "£400,000"
    },
    { 
       value : "450000",
       label: "£450,000"
    },
    { 
       value : "500000",
       label: "£500,000"
    },
    { 
       value : "550000",
       label: "£550,000"
    },
    { 
       value : "600000",
       label: "£600,000"
    },
    { 
       value : "650000",
       label: "£650,000"
    },
    { 
       value : "700000",
       label: "£700,000"
    },
    { 
       value : "750000",
       label: "£750,000"
    },
    { 
       value : "800000",
       label: "£800,000"
    },
    { 
       value : "850000",
       label: "£850,000"
    },
    { 
       value : "900000",
       label: "£900,000"
    },
    { 
       value : "950000",
       label: "£950,000"
    },
    { 
       value : "1000000",
       label: "£1,000,000"
    }
]

const salepricemaxrange = [
    {
       value: "50000",
       label: "£50,000"
    },
    { 
       value : "60000",
       label: "£60,000"
    },
    { 
       value : "70000",
       label: "£70,000"
    },
    { 
       value : "80000",
       label: "£80,000"
    },
    { 
       value : "90000",
       label: "£90,000"
    },
    { 
       value : "100000",
       label: "£100,000"
    },
    { 
       value : "120000",
       label: "£120,000"
    },
    { 
       value : "140000",
       label: "£140,000"
    },
    { 
       value : "160000",
       label: "£160,000"
    },
    { 
       value : "180000",
       label: "£180,000"
    },
    { 
       value : "200000",
       label: "£200,000"
    },
    { 
       value : "225000",
       label: "£225,000"
    },
    { 
       value : "275000",
       label: "£275,000"
    },
    { 
       value : "300000",
       label: "£300,000"
    },
    { 
       value : "325000",
       label: "£325,000"
    },
    { 
       value : "350000",
       label: "£350,000"
    },
    { 
       value : "375000",
       label: "£375,000"
    },
    { 
       value : "400000",
       label: "£400,000"
    },
    { 
       value : "450000",
       label: "£450,000"
    },
    { 
       value : "500000",
       label: "£500,000"
    },
    { 
       value : "550000",
       label: "£550,000"
    },
    { 
       value : "600000",
       label: "£600,000"
    },
    { 
       value : "650000",
       label: "£650,000"
    },
    { 
       value : "700000",
       label: "£700,000"
    },
    { 
       value : "750000",
       label: "£750,000"
    },
    { 
       value : "800000",
       label: "£800,000"
    },
    { 
       value : "850000",
       label: "£850,000"
    },
    { 
       value : "900000",
       label: "£900,000"
    },
    { 
       value : "950000",
       label: "£950,000"
    },
    { 
       value : "1000000",
       label: "£1,000,000"
    }
]

const rentpriceminrange = [
    {
    value : "350",
    label : "£350 PCM"
},
{
    value : "450",
    label : "£450 PCM"
},
{
    value : "500",
    label : "£500 PCM"
},
{
    value : "550",
    label : "£550 PCM"
},
{
    value : "600",
    label : "£600 PCM"
},
{
    value : "650",
    label : "£650 PCM"
},
{
    value : "700",
    label : "£700 PCM"
},
{
    value : "800",
    label : "£800 PCM"
},
{
    value : "1000",
    label : "£1000 PCM"
},
{
    value : "1500",
    label : "£1500 PCM"
},
{
    value : "2000",
    label : "£2000 PCM"
}
]

const rentpricemaxrange = [
{
    value : "350",
    label : "£350 PCM"
},
{
    value : "450",
    label : "£450 PCM"
},
{
    value : "500",
    label : "£500 PCM"
},
{
    value : "550",
    label : "£550 PCM"
},
{
    value : "600",
    label : "£600 PCM"
},
{
    value : "650",
    label : "£650 PCM"
},
{
    value : "700",
    label : "£700 PCM"
},
{
    value : "800",
    label : "£800 PCM"
},
{
    value : "1000",
    label : "£1000 PCM"
},
{
    value : "1500",
    label : "£1500 PCM"
},
{
    value : "2000",
    label : "£2000 PCM"
}
]

export class PropertyForm extends Component {
  constructor() {
      super();
      this.state = {
          salemin:"",
          salemax:"",
          location:"",
          showbedroom: true,
          showsaleprice: true,
          showrentprice: false,
          showrentmaxprice: true,
          showsalemaxprice: true,
          popminSalesPriceRange_s: salepriceminrange,
          popmaxSalesPriceRange_s: salepricemaxrange,
          popminRentalsPriceRange_s: rentpriceminrange,
          popmaxRentalsPriceRange_s: rentpricemaxrange
      }
  }
  handleChange = (event) => {
    if ("query" === event.currentTarget.name) {
      this.setState({
        location : event.currentTarget.value
      })
  }
    console.log(event.currentTarget.value)
    if ( "sales" === event.currentTarget.value ) 
    {
      this.setState({showbedroom: true, showsaleprice: true, showrentprice: false, showbuildtype: false});
    }
    if ( "rentals" === event.currentTarget.value ) 
    {
      this.setState({showbedroom: true, showsaleprice: false, showrentprice: true, showbuildtype: false});
    }
  }
   handleSelectPriceChange = (event) => {
    if ( "price_min_sales" == event.currentTarget.name ) {
      if (event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
        this.setState({ popmaxSalesPriceRange_s : salepricemaxrange.filter((x) => parseInt(x.value) > parseInt(event.currentTarget.value) ) })
        if ( "1000000"  == event.currentTarget.value ) {
          this.setState({ showsalemaxprice : false })
        } else {
          this.setState({ showsalemaxprice : true })
        }
      }
      else {
        this.setState({ popmaxSalesPriceRange_s : salepricemaxrange })
        this.setState({ showsalemaxprice : true })
      }
    }
    if ( "price_max_sales" == event.currentTarget.name ) {
      if (event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
        this.setState({ popminSalesPriceRange_s : salepriceminrange.filter((x) => parseInt(x.value) < parseInt(event.currentTarget.value) ) })
      }
      else
        this.setState({ popminSalesPriceRange_s : salepriceminrange })
    }
    if ( "price_min_rentals" == event.currentTarget.name ) {
      if (event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
        this.setState({ popmaxRentalsPriceRange_s : rentpricemaxrange.filter((x) => parseInt(x.value) > parseInt(event.currentTarget.value) ) })
        if ( "2000"  == event.currentTarget.value ) {
          this.setState({ showrentmaxprice : false })
        } else {
          this.setState({ showrentmaxprice : true })
        }
      }
      else {
        this.setState({ popmaxRentalsPriceRange_s : rentpricemaxrange })
        this.setState({ showrentmaxprice : true })
      }
    }
    if ( "price_max_rentals" == event.currentTarget.name ) {
      if (event.currentTarget.value !== undefined && event.currentTarget.value !== "") {
        this.setState({ popminRentalsPriceRange_s : rentpriceminrange.filter((x) => parseInt(x.value) < parseInt(event.currentTarget.value) ) })
      }
      else
        this.setState({ popminRentalsPriceRange_s : rentpriceminrange })
    }
  }

  // TODO: hard coded details to be removed/replaced with common variables
handleSubmit = (event) => {
    event.preventDefault();
    const formsdata = (event.target);
    const json = {}
    Object.keys(formsdata).map((key) => {
      json[formsdata[key].name] = formsdata[key].value
    })
    console.log(json)
    //json['property_type'] = "rentals";

    let url = "/";
    if (json['query'] !== "") {
      url = url+"in-"+json['query']+"/"
    }
    else {
      url = url+"in-west-yorkshire/"
    }
    if (json['property_type'] === "sales" || json['property_type'] === "rentals") {
      if (json['bedroom'] !== "") {
        url = url+json['bedroom']+"-and-more-bedrooms/"
      }
      if (json['property_type'] === "sales") {
        if (json['price_min_sales'] !== "" && json['price_max_sales'] !== "") {
          url = url+"between-"+json['price_min_sales']+"-and-"+json['price_max_sales']
        }
        else if (json['price_max_sales'] !== "") {
          url = url+"under-"+json['price_max_sales']
        }
        else if (json['price_min_sales'] !== "") {
          url = url+"over-"+json['price_min_sales']
        }
      }
      if (json['property_type'] === "rentals") {
        if (json['price_min_rentals'] !== "" && json['price_max_rentals'] !== "") {
          url = url+"between-"+json['price_min_rentals']+"-and-"+json['price_max_rentals']
        }
        else if (json['price_max_rentals'] !== "") {
          url = url+"under-"+json['price_max_rentals']
        }
        else if (json['price_min_rentals'] !== "") {
          url = url+"over-"+json['price_min_rentals']
        }
      }
    }

    if ( "/" === url.substr(url.length -1) )
      url = url.substr(0, url.length -1)

    if (json['property_type'] === "sales") {
      navigate("/property/sales"+url);
      setTimeout(() => {
         $( ".custom-modal__close" ).trigger( "click" )
       },100);
      
    } else if (json['property_type'] === "rentals") {
      navigate("/property/rentals"+url);
      setTimeout(() => {
         $( ".custom-modal__close" ).trigger( "click" )
       },100);
      
    }
  }
 render() {
  return (
    <>
    <div className={classNames('property-form')}>
      <h3 className="text-center">Property Search</h3>
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <div className="custom-float custom-float--select" >
            <Form.Label>Search Type</Form.Label>
                       <Form.Control as="select"  name="property_type" onChange={this.handleChange}>
                          <option value="sales" key="0" selected="selected">Sales</option>
                          <option value="rentals" key="1">Rentals</option>
                       </Form.Control>
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className="custom-float">
            <Form.Label>Location</Form.Label>
            <Form.Control 
              id="query"
              name="query"
              value={this.state.location}
              onChange={this.handleChange}
            />
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className={!this.state.showsaleprice ? 'd-none' : 'custom-float custom-float--select'} >
            <Form.Label>Minimum Price</Form.Label>
<Form.Control as="select"  name="price_min_sales" onChange={this.handleSelectPriceChange}>
                          <option value="" key="0" aria-label="empty"></option>
                            {this.state.popminSalesPriceRange_s.map((option, key) => {
                                return(
                                  <option value={option.value} key={key}>
                                    {option.label}
                                  </option>
                                )
                              })
                            }
                       </Form.Control>
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className={!this.state.showsaleprice ? 'd-none' : 'custom-float custom-float--select'}>
            <Form.Label>Maximum Price</Form.Label>
            <Form.Control as="select" name="price_max_sales" disabled={this.state.showsalemaxprice ? '' : 'disabled'}  onChange={this.handleSelectPriceChange}>
                          <option value="" key="0" aria-label="empty"></option>
                            {
                              (this.state.popmaxSalesPriceRange_s).map((option, key) => {
                                return(
                                  <option value={option.value} key={key}>
                                    {option.label}
                                  </option>
                                )
                              })
                            }
                       </Form.Control>
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className={!this.state.showrentprice ? 'd-none' : 'custom-float custom-float--select'}>
            <Form.Label>Minimum Price</Form.Label>
<Form.Control as="select" name="price_min_rentals" onChange={this.handleSelectPriceChange}>
                          <option value="" key="0"></option>
                            {
                              (this.state.popminRentalsPriceRange_s).map((option, key) => {
                                return(
                                  <option value={option.value} key={key}>
                                    {option.label}
                                  </option>
                                )
                              })
                            }
                       </Form.Control>
</div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className={!this.state.showrentprice ? 'd-none' : 'custom-float custom-float--select'}>
            <Form.Label>Maximum Price</Form.Label>
            <Form.Control as="select" name="price_max_rentals" disabled={this.state.showrentmaxprice ? '' : 'disabled'} onChange={this.handleSelectPriceChange}>
                          <option value="" key="0"></option>
                            {
                              (this.state.popmaxRentalsPriceRange_s).map((option, key) => {
                                return(
                                  <option value={option.value} key={key}>
                                    {option.label}
                                  </option>
                                )
                              })
                            }
                       </Form.Control>
</div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div className="custom-float custom-float--select">
            <Form.Label>Bedrooms</Form.Label>
            <Form.Control 
              as="select" 
              name="bedroom" 
              id="bedroom"
            >
              <option value="" aria-label="empty"></option>
              <option value="1">1+ Bedrooms</option>
              <option value="2">2+ Bedrooms</option>
              <option value="3">3+ Bedrooms</option>
              <option value="4">4+ Bedrooms</option>
              <option value="5">5+ Bedrooms</option>
            </Form.Control>
          </div>
          <Form.Text className="form-helper">
            Helper Text
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          Search
        </Button>
      </Form>
    </div>
    </>
  )
}
}

export default PropertyForm;
