import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';

const FooterCol = ({children, title, className}) => {
  const [active, setActive] = useState(false);
  const handleClick = () => setActive(!active);
  return (
    <Col lg={3} md={6} className={classNames(className, {'is-active': active})}>
      <h6 onClick={handleClick} onKeyDown={handleClick} role="presentation">{title}</h6>
      {children}
    </Col>
  )
}

export default FooterCol;
