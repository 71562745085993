import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Link } from "gatsby";
import $ from "jquery";

import { POPULAR_SEARCH_HEADING } from "./constants";

import { getUrlName } from "@components/common/site/utils";

const PopularSearchAccordion = (props) => {

    const [active, setActive] = useState(false);

    let _props = {...props};

    let _col1Links = _props.col1Links || [];
    let _col2Links = _props.col2Links || [];
    let _col3Links = _props.col3Links || [];
    let _col4Links = _props.col4Links || [];

    // Remove (if any) empty items
    _col1Links = _col1Links.filter(e => e);
    _col2Links = _col2Links.filter(e => e);
    _col3Links = _col3Links.filter(e => e);
    _col4Links = _col4Links.filter(e => e);

    let toggleClass = () => {
        setActive(!active);
    }

    useEffect(() => {
        let footerPopularOne = $('.footer_popular_one');
        let cardHeader = $('.card-header', footerPopularOne);
        let collapseShow = $('.collapse.show', footerPopularOne);
        if (collapseShow) {
            cardHeader.addClass('show');
        }
    }, [active]);

    // if there is no links to show, then return
    if ( !(_col1Links && _col2Links && _col3Links && _col4Links ) ) {
        return null;
    }

    return (
            <Row className="popular-search">
                <Col lg={12}>
                    <div className="footer-divider-line"></div>

                        <Accordion className='footer_popular_one' onClick={toggleClass}>

                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey="0"
                                className={"text-uppercase" + (active ? ' active' : '')}>
                                {POPULAR_SEARCH_HEADING}
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row>

                                        {/* {Column 1} */}
                                        <Col xl={3} md={6} className="mb-md-5 mb-0 column-div">
                                            <ul className="list-group list-group-flush">
                                                {_col1Links && _col1Links.map((url, index) => {
                                                    return (
                                                        <li className="list-group-item">
                                                            <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Col>

                                        {/* {Column 2} */}
                                        <Col xl={3} md={6} className="mb-md-5 mb-0 column-div">
                                            <ul className="list-group list-group-flush">
                                                {_col2Links && _col2Links.map((url) => {
                                                    return (
                                                        <li className="list-group-item">
                                                            <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Col>

                                        {/* {Column 3} */}
                                        <Col xl={3} md={6} className="mb-md-5 mb-0 column-div property-ipad">
                                            <ul className="list-group list-group-flush">
                                                {_col3Links && _col3Links.map((url) => {
                                                    return (
                                                        <li className="list-group-item">
                                                            <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Col>

                                        {/* {Column 4} */}
                                        <Col xl={3} md={6} className="mb-md-5 mb-0 column-div property-ipad">
                                            <ul className="list-group list-group-flush">
                                                {_col4Links && _col4Links.map((url) => {
                                                    return (
                                                        <li className="list-group-item">
                                                            <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Col>
                                        
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>

                        </Accordion>
                    <div className="footer-divider-line"></div>
                </Col>
            </Row>
    )
}

export default PopularSearchAccordion;