import React from 'react';
import classNames from 'classnames';

/**
 * Assets
 */
import './assets/styles/_index.scss';

const Icon = ({children, className}) => {
  return (
    <div className={classNames('icon-holder', className)}>
      {children}
    </div>
  )
}

export default Icon;
