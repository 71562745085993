import { URLS } from "@components/common/site/constants";

let _URLS = URLS;

// --------------------------------------------------------------------------
// General functions - Start
export const addLinksToArray= (links, linksArray) => {
  if (links && linksArray) {
    links.map((link) => {
      linksArray.push(link);
    });
  }
}

export const getUrlName = (url) => {
  if (url) {
    return url.name2 ?? url.name;
  }
}

export const getUrlHref = () => {
  let urlHref = '';
  if (typeof window !== 'undefined') {
    urlHref = window.location.href;
  }
  return urlHref;
}

export const getUrlPathName = () => {
  let urlPathName = '';
  if (typeof window !== 'undefined') {
    urlPathName = window.location.pathname;
    urlPathName = urlPathName.toLowerCase();
  }
  return urlPathName;
}

export const getUrlLastPart = () => {
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  var url = urlPathName.split('/');
  // console.log('url : ' + url);
  let [last] = [...url].reverse();
  // console.log('last : ' + last);
  return last;
}

export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}
// General functions - End  
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// is...Pages() Or is...Page() - functions - Start
export const isBuyPages = () => {
  let isBuyPages = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTY_SERVICES_BUY.alias : ' + _URLS.PROPERTY_SERVICES_BUY.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.PROPERTY_SERVICES_BUY.alias ) > -1 ) {
    isBuyPages = true;
  }
  // console.log('isBuyPages : ' + isBuyPages);
  return isBuyPages;
}

export const isSellPages = () => {
  let isSellPages = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTY_SERVICES_SELL.alias : ' + _URLS.PROPERTY_SERVICES_SELL.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.PROPERTY_SERVICES_SELL.alias ) > -1 ) {
    isSellPages = true;
  }
  // console.log('isSellPages : ' + isSellPages);
  return isSellPages;
}

export const isRentPages = () => {
  let isRentPages = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTY_SERVICES_RENT.alias : ' + _URLS.PROPERTY_SERVICES_RENT.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.PROPERTY_SERVICES_RENT.alias ) > -1 ) {
    isRentPages = true;
  }
  // console.log('isRentPages : ' + isRentPages);
  return isRentPages;
}

export const isOfficePages = () => {
  let isOfficePages = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.CONTACT.alias : ' + _URLS.CONTACT.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.CONTACT.alias ) > -1 ) {
    isOfficePages = true;
  }
  // console.log('isOfficePages : ' + isOfficePages);
  return isOfficePages;
}

export const isAreaGuidesPages = () => {
  let isAreaGuidesPages = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.AREA_GUIDES.alias : ' +  _URLS.AREA_GUIDES.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.AREA_GUIDES.alias ) > -1 ) {
    isAreaGuidesPages = true;
  }
  // console.log('isAreaGuidesPages : ' + isAreaGuidesPages);
  return isAreaGuidesPages;
}

export const isAreaGuidesLandingPage = () => {
  let isAreaGuidesLandingPage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.AREA_GUIDES.alias : ' + _URLS.AREA_GUIDES.alias );
  if ( urlPathName && urlPathName === _URLS.AREA_GUIDES.alias ) {
    isAreaGuidesLandingPage = true;
  }
  // console.log('isAreaGuidesLandingPage : ' + isAreaGuidesLandingPage);
  return isAreaGuidesLandingPage;
}

export const isAreaGuidesDetailsPage = () => {
  let isAreaGuidesDetailsPage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.AREA_GUIDES.alias : ' + _URLS.AREA_GUIDES.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.AREA_GUIDES.alias ) > -1 &&
       urlPathName !== _URLS.AREA_GUIDES.alias ) {
    isAreaGuidesDetailsPage = true;
  }
  // console.log('isAreaGuidesDetailsPage : ' + isAreaGuidesDetailsPage);
  return isAreaGuidesDetailsPage;
}

export const isPropertyDetailsPage = () => {
  let isPropertyDetailsPage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTY_DETAILS.alias : ' + _URLS.PROPERTY_DETAILS.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.PROPERTY_DETAILS.alias ) > -1 ) {
    isPropertyDetailsPage = true;
  }
  // console.log('isPropertyDetailsPage : ' + isPropertyDetailsPage);
  return isPropertyDetailsPage;
}

export const isPropertiesResultsPage = () => {
  let isPropertiesResultsPage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTIES_RESULTS_SALES.alias : ' + _URLS.PROPERTIES_RESULTS_SALES.alias );
  // console.log( '_URLS.PROPERTIES_RESULTS_RENTALS.alias : ' + _URLS.PROPERTIES_RESULTS_RENTALS.alias );
  if ( urlPathName && 
      ( urlPathName.indexOf( _URLS.PROPERTIES_RESULTS_SALES.alias ) > -1 || 
        urlPathName.indexOf( _URLS.PROPERTIES_RESULTS_RENTALS.alias ) > -1
      )
    ) {
    isPropertiesResultsPage = true;
  }
  // console.log('isPropertiesResultsPage : ' + isPropertiesResultsPage);
  return isPropertiesResultsPage;
}
// is...Pages() Or is...Page() - functions - End
// --------------------------------------------------------------------------