import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@styles/main.scss';

/**
 * Components
 */
import Header from '@components/header/header';
import Footer from '@components/footer/footer';
import Modals from '@components/modals/modals';

const Default = (props) => {
  return (
    <div className="wrapper">
      <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=g8mx2rsgoxm3e7zksfenog" async={true}></script>
      <Header />
        {props.children}
      <Footer {...props} />
      <Modals />
    </div>
  );
}

export default Default;