export const AREAS = {
    WEST_YORKSHIRE: {
      name: 'West Yorkshire',
      alias: 'west-yorkshire'
    },
    KEIGHLEY: {
      name: 'Keighley',
      alias: 'keighley'
    },
    BINGLEY: {
      name: 'Bingley',
      alias: 'bingley'
    },
    BRADFORD: {
      name: 'Bradford',
      alias: 'bradford'
    },
    OAKWORTH: {
      name: 'Oakworth',
      alias: 'oakworth'
    },
    OXENHOPE: {
      name: 'Oxenhope',
      alias: 'oxenhope'
    }
}

export const AREAS_NAMES_ALL = Object.keys(AREAS);

export const AREAS_NAMES   = ['WEST_YORKSHIRE', 'KEIGHLEY', 'BINGLEY', 'BRADFORD', 'OAKWORTH'];
export const AREAS_NAMES_1 = ['WEST_YORKSHIRE', 'KEIGHLEY', 'BINGLEY'];
export const AREAS_NAMES_2 = ['BRADFORD', 'OAKWORTH', 'OXENHOPE'];

// TODO: Get all the URLs/alias from Strapi and use it here
export const URLS = {
    VALUATION: {
      name: 'Property Valuation',
      alias: '/property-valuation'
    },
    ABOUT: {
      name: 'About',
      alias: '/about'
    },
    ABOUT_HOLROYDS: {
        name: 'About Holroyds',
        alias: '/about/holroyds',
        name2: 'About Holroyds Estate Agents'
    },
    CONTACT: { // Our Office
      name: 'Contact West Yorkshire Estate Agents',
      alias: '/contact-west-yorkshire-estate-agents'
    },
    CONTACT_KEIGHLEY: { // Keighley Office
      name: 'Keighley Estate Agents',
      alias: '/contact-west-yorkshire-estate-agents/keighley-estate-agents'
    },
    CONTACT_BAILDON: { // Baildon Office
      name: 'Baildon Estate Agents',
      alias: '/contact-west-yorkshire-estate-agents/baildon-estate-agents'
    },
    CONTACT_BINGLEY: { // Bingley Office
      name: 'Bingley Estate Agents',
      alias: '/contact-west-yorkshire-estate-agents/bingley-estate-agents'
    },
    WHY_USE_HOLROYDS: {
      name: 'Why Use Holroyds',
      alias: '/about/why-use-holroyds'
    },
    MEET_THE_TEAM: {
      name: 'Meet the Team',
      alias: '/about/meet-the-team',
      name2: 'Meet Our Property Experts'
    },
    TESTIMONIALS: {
      name: 'Testimonials',
      alias: '/about/testimonials'
    },
    CAREERS: {
      name: 'Careers',
      alias: '/about/careers'
    },
    LATEST_NEWS: {
      name: 'Latest News',
      alias: '/about/latest-news',
      name2: 'Latest Property News'
    },
    AREA_GUIDES: {
      name: 'Living in West Yorkshire',
      alias: '/about/living-in-west-yorkshire'
    },
    AREA_GUIDES_KEIGHLEY: {
      name: 'Keighley',
      alias: '/about/living-in-west-yorkshire/keighley'
    },
    AREA_GUIDES_BINGLEY: {
      name: 'Bingley',
      alias: '/about/living-in-west-yorkshire/bingley'
    },
    AREA_GUIDES_OXENHOPE: {
      name: 'Oxenhope',
      alias: '/about/living-in-west-yorkshire/oxenhope'
    },
    PROPERTY_SERVICES: { // What We Do
      name: 'Property Services',
      alias: '/property-services',
      name2: 'Our Property Services'
    },
    PROPERTY_SERVICES_BUY: {
      name: 'Buy',
      alias: '/property-services/buy'
    },
    PROPERTY_SERVICES_SELL: {
      name: 'Sell',
      alias: '/property-services/sell'
    },
    PROPERTY_SERVICES_RENT: {
      name: 'Rent',
      alias: '/property-services/rent'
    },
    PROPERTY_SERVICES_LANDLORDS: {
      name: 'Landlords',
      alias: '/property-services/landlords'
    },
    LANDLORDS_PROPERTY_MANAGEMENT: {
      name: 'Property Management',
      alias: '/property-services/landlords/property-management'
    },
    PROPERTY_SERVICES_MORTGAGES: {
      name: 'Mortgages',
      alias: '/property-services/mortgages'
    },
    PROPERTIES_RESULTS_SALES: {
      name: 'Properties for sale',
      alias: '/property/sales/',
      name2: 'Property for sale'
    },
    PROPERTIES_RESULTS_RENTALS: {
      name: 'Properties to rent',
      alias: '/property/rentals/',
      name2: 'Property to rent'
    },
    PROPERTY_DETAILS: {
      name: 'Property details',
      alias: '/property-detail/'
    }
}

export const AREA_GUIDE_URLS = {
  KEIGHLEY: {
    name: URLS.AREA_GUIDES_KEIGHLEY.name,
    alias: URLS.AREA_GUIDES_KEIGHLEY.alias
  },
  BINGLEY: {
    name: URLS.AREA_GUIDES_BINGLEY.name,
    alias: URLS.AREA_GUIDES_BINGLEY.alias
  },
  OXENHOPE: {
    name: URLS.AREA_GUIDES_OXENHOPE.name,
    alias: URLS.AREA_GUIDES_OXENHOPE.alias
  }
}
export const AREA_GUIDE_AREAS = Object.keys(AREA_GUIDE_URLS);

export const SEARCH_LOCATION = AREAS.WEST_YORKSHIRE.name; // Default Search Location
export const DEFAULT_AREA = AREAS.WEST_YORKSHIRE;
export const ABOUT_PAGE_URL = URLS.ABOUT;
export const ABOUT_HOLROYDS_PAGE_URL = URLS.ABOUT_HOLROYDS;
export const CONTACT_PAGE_URL = URLS.CONTACT;
export const CONTACT_KEIGHLEY_PAGE_URL = URLS.CONTACT_KEIGHLEY;
export const CONTACT_BAILDON_PAGE_URL = URLS.CONTACT_BAILDON;
export const CONTACT_BINGLEY_PAGE_URL = URLS.CONTACT_BINGLEY;
export const WHY_USE_HOLROYDS_PAGE_URL = URLS.WHY_USE_HOLROYDS;
export const VALUATION_PAGE_URL = URLS.VALUATION;
export const MEET_THE_TEAM_PAGE_URL = URLS.MEET_THE_TEAM;
export const TESTIMONIALS_PAGE_URL = URLS.TESTIMONIALS;
export const CAREERS_PAGE_URL = URLS.CAREERS;
export const LATEST_NEWS_PAGE_URL = URLS.LATEST_NEWS;
export const AREA_GUIDES_PAGE_URL = URLS.AREA_GUIDES;
export const PROPERTY_SERVICES_PAGE_URL = URLS.PROPERTY_SERVICES;
export const PROPERTY_SERVICES_BUY_PAGE_URL = URLS.PROPERTY_SERVICES_BUY;
export const PROPERTY_SERVICES_SELL_PAGE_URL = URLS.PROPERTY_SERVICES_SELL;
export const PROPERTY_SERVICES_RENT_PAGE_URL = URLS.PROPERTY_SERVICES_RENT;
export const PROPERTY_SERVICES_LANDLORDS_PAGE_URL = URLS.PROPERTY_SERVICES_LANDLORDS;
export const LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL = URLS.LANDLORDS_PROPERTY_MANAGEMENT;
export const PROPERTY_SERVICES_MORTGAGES_PAGE_URL = URLS.PROPERTY_SERVICES_MORTGAGES;