/**
 * The external dependencies.
 */
import { useContext } from 'react';

/**
 * The internal dependencies.
 */
import AppContext from './store';
import modalServices from '@store/modal/services';
import propertiesServices from '@store/properties/services';

import * as modalActions from '@store/modal/actions';
import * as propertiesActions from '@store/properties/actions';


const useGlobalState = (namespace = null) => {
	const [state, dispatch] = useContext(AppContext);
	const store = {
    state: { ...state },
    services: {
			modals: { ...modalServices(dispatch, modalActions) },
			properties: { ...propertiesServices(dispatch, propertiesActions) },
    }
	};

	if (namespace && typeof store.state[namespace] !== 'undefined') {
		return {
      state: { ...store.state[namespace] },
      services: { ...store.services[namespace] },
		};
	}

	return store;
};

export { useGlobalState };
