import React from "react";

import { addLinksToArray } from "@components/common/site/utils";
import {
    VALUATION_PAGE_URL,
    ABOUT_HOLROYDS_PAGE_URL,
    CONTACT_PAGE_URL,
    LATEST_NEWS_PAGE_URL,
    PROPERTY_SERVICES_MORTGAGES_PAGE_URL,
    PROPERTY_SERVICES_PAGE_URL,
    AREA_GUIDES_PAGE_URL,
    TESTIMONIALS_PAGE_URL,
    LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL,
    MEET_THE_TEAM_PAGE_URL,
    WHY_USE_HOLROYDS_PAGE_URL,
    CONTACT_KEIGHLEY_PAGE_URL,
    CONTACT_BAILDON_PAGE_URL,
    CONTACT_BINGLEY_PAGE_URL,
    CAREERS_PAGE_URL
} from "@components/common/site/constants";
import { SEARCH_TYPES } from "@components/common/property/constants";

import { areasUrls } from "../utils";
import PopularSearchAccordion from "../accordion";

const PopularSearchStaticDefault2 = (props) => {
    
    let _props = {...props};

    let _areasSalesUrls = areasUrls({searchType: SEARCH_TYPES.SALES});

    let col1Links = [];
    addLinksToArray(_areasSalesUrls, col1Links);
    
    let col2Links = [];
    col2Links.push(VALUATION_PAGE_URL);
    col2Links.push(ABOUT_HOLROYDS_PAGE_URL);
    col2Links.push(CONTACT_PAGE_URL);
    col2Links.push(LATEST_NEWS_PAGE_URL);
    col2Links.push(PROPERTY_SERVICES_MORTGAGES_PAGE_URL);

    let col3Links = [];
    col3Links.push(PROPERTY_SERVICES_PAGE_URL);
    col3Links.push(AREA_GUIDES_PAGE_URL);
    col3Links.push(TESTIMONIALS_PAGE_URL);
    col3Links.push(LANDLORDS_PROPERTY_MANAGEMENT_PAGE_URL);
    col3Links.push(MEET_THE_TEAM_PAGE_URL);

    let col4Links = [];
    col4Links.push(WHY_USE_HOLROYDS_PAGE_URL);
    col4Links.push(CONTACT_KEIGHLEY_PAGE_URL);
    col4Links.push(CONTACT_BAILDON_PAGE_URL);
    col4Links.push(CONTACT_BINGLEY_PAGE_URL);
    col4Links.push(CAREERS_PAGE_URL);
    
    _props.col1Links = col1Links;
    _props.col2Links = col2Links;
    _props.col3Links = col3Links;
    _props.col4Links = col4Links;

    return (
        <div class="component-PopularSearchStaticDefault2">
            <PopularSearchAccordion {..._props} />
        </div>
    )
}

export default PopularSearchStaticDefault2;