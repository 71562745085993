import React from "react";

import { getArea } from "@components/common/property/utils";

import PopularSearchStaticDefault from '../../static/default';
import PopularSearchDynamicPropertyDetailsSales from './sales';
import PopularSearchDynamicPropertyDetailsRentals from './rentals';

const PopularSearchDynamicPropertyDetailsDefault = (props) => {

    let _props = {...props};

    let _propDetailsData = _props.propDetailsData || ''; // propDetailsData is from props
    // console.log(_propDetailsData);

    let propType = _propDetailsData ? _propDetailsData.search_type : '';
    propType = propType.toUpperCase();
    // console.log('propType : ' + propType);

    let area = '';

    let addressDetails = _propDetailsData ? _propDetailsData.address_details : '';
    // console.log(addressDetails);

    let displayAddress = _propDetailsData ? _propDetailsData.display_address : '';
    // console.log(displayAddress);

    area = displayAddress ? getArea(displayAddress) : '';
    // console.log('area : ' + area);

    let address3 = addressDetails ? addressDetails.address3 : '';
    // console.log('address3 : ' + address3);

    area = address3 ?? (addressDetails.address2 ?? '');
    // console.log('area : ' + area);

    _props.area = area;

    const renderPopularSearchItems = () => {
        switch (propType) {
            case 'SALES':
                return <PopularSearchDynamicPropertyDetailsSales {..._props} />;
            case 'LETTINGS':
                return <PopularSearchDynamicPropertyDetailsRentals {..._props} />;
            default:
                return <PopularSearchStaticDefault {..._props} />;
        }
    }

    return (
        <div class="component-PopularSearchDynamicPropertyDetailsDefault">
            {renderPopularSearchItems()}
        </div>
    )
}

export default PopularSearchDynamicPropertyDetailsDefault;