import React, { useState, Fragment } from 'react';
import { Link } from 'gatsby';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

/**
 * Context API
 */
import { useGlobalState } from '@store/hooks';

/**
 * Assest
 */
import './assets/styles/_index.scss';
import CloseIcon from '@static/svg/close_icon.svg';

const Navigation = ({active, navigationToggle}) => {
  const [expanded, setExpanded] = useState(false);
  const { services: modalServices } = useGlobalState('modals');
  const handleClick = () => {
    navigationToggle();
    setExpanded(false);
  };

  const handleExpand = (event) => setExpanded(!event.target.parentNode.classList.contains('show'));
  const handleClose = () => expanded ? setExpanded(false) : navigationToggle();
  const modalOpen = (event) => {
    event.preventDefault();
    navigationToggle(false);
    modalServices.modalToggle({name: 'property_search', active: true});
  }

  const data = useStaticQuery(graphql`
    query {
      strapiMenuBurger {
        Menu {
          Parent_Label
          Parent_URL
          Sub_Menus {
            Label
            URL
          }
        }
      }
    }
  `);

  return (
    <Fragment>
      <div className={classNames('toggle-nav', {'is-active': active})} onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
        Navigation Button
        <span></span>
      </div>
      <div className={classNames('navigation', {'is-active': active, 'is-expanded': expanded})}>
        <div className="navigation__overlay" role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleClick}>Navigation Overlay</div>
        <div className={classNames('navigation__close', {'is-active': active})} role="button" tabIndex={0} onClick={handleClose} onKeyDown={handleClose}>
          <CloseIcon />
        </div>
        <div className="navigation__inner">
          <Navbar expand={false}>
              <Nav className="nav">
                {data.strapiMenuBurger.Menu.map((Menu, index) => {
                  return (
                    <>
                    {Menu.Sub_Menus == "" && Menu.Parent_URL != "#search" &&
                    <Link to={`/${Menu.Parent_URL}`} className="nav-link">{Menu.Parent_Label}</Link>
                    }
                    {Menu.Sub_Menus == "" && Menu.Parent_URL == "#search" &&
                    <Nav.Link href="/" onClick={modalOpen} className="nav-link">{Menu.Parent_Label}</Nav.Link>
                    }
                    {Menu.Sub_Menus != "" &&
                      <NavDropdown title={Menu.Parent_Label} onClick={handleExpand}>
                        <NavDropdown.Item className="back" role="button" tabIndex={0} onClick={handleClose} onKeyDown={handleClose}>&lt; Back</NavDropdown.Item>
                          {Menu.Sub_Menus.map((Sub_Menus, index) => {
                            return (
                              <>
                                  <Link to={`/${Sub_Menus.URL}`} className="dropdown-item">{Sub_Menus.Label} </Link>
                              </>
                            )
                          })}
                      </NavDropdown>
                    }
                    </>
                  )
                })}
            </Nav>
          </Navbar>
        </div>
      </div>
    </Fragment>
  )
}

export default Navigation;
