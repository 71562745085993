import React from 'react';
import classNames from 'classnames';
import $ from 'jquery'

/** 
 * Assets
 */
import './assets/styles/_index.scss'
import CloseIcon from './assets/img/close_icon.svg';

const Modal = ({children, active, onClose, className}) => {

  const onCloseClass = (event) => {
    $("html").removeClass("bg-scroll-disable");
    onClose();
  }


  return (
    <div className={classNames('custom-modal', className, {'is-active': active})}>
      <div className="custom-modal__inner">
        <div className="custom-modal__close" onClick={onCloseClass} onKeyDown={onClose} role="presentation">Close <span><CloseIcon /></span></div>
        {children}
      </div>
    </div>
  )
}

export default Modal;
