import { propertyH1 } from "@components/common/property/utils";
import {
    AREAS,
    AREAS_NAMES
} from "@components/common/site/constants";
import * as SALES from "@pages/property/sales.js";
import * as RENTALS from "@pages/property/rentals.js";
import { SEARCH_TYPES } from "@components/common/property/constants";
import {
  getArea,
  CheckArea,
  populateSearchState,
  populateSearchData
} from "@components/common/property/utils";
import { getUrlPathName } from "@components/common/site/utils";

import { POPULAR_SEARCH_BED_ROOMS_NUMBERS } from "./constants";

let _AREAS = AREAS;
let _AREAS_NAMES = AREAS_NAMES;
let _SALES = SALES;
let _RENTALS = RENTALS;
let _SEARCH_TYPES = SEARCH_TYPES;
let _BED_ROOMS_NUMBERS = POPULAR_SEARCH_BED_ROOMS_NUMBERS;

export const areasUrls = (params) => {
    let areasUrls = [];
    let _searchType = params.searchType || _SEARCH_TYPES.SALES;
    let _beds = params.beds || '';
    let _areas = params.areas || _AREAS_NAMES; // or _AREAS_NAMES_1 or _AREAS_NAMES_2
    let _urlType = _SALES;
    switch (_searchType) {
        case _SEARCH_TYPES.SALES:
            _urlType = _SALES;
        break;
        case _SEARCH_TYPES.RENTALS:
            _urlType = _RENTALS;
        break;
    }
    _areas.map((area) => {
      let _areasUrls = {};
      let state = populateSearchState({areaAlias: _AREAS[area].alias});
      state.range.bedroom.min = _beds;
      let data = populateSearchData({areaName: _AREAS[area].name, pType: _searchType});
      data.bedrooms = _beds;
      _areasUrls.name = propertyH1(data);
      _areasUrls.alias = _urlType.createURL({}, state);
      let urlPathName = getUrlPathName();
      if ( urlPathName && (urlPathName === _areasUrls.alias) ) { // if same page url ...
        // ... ignore it
      } else {
        areasUrls.push(_areasUrls);
      }
    });
    return areasUrls;
}
  
export const bedRoomsUrls = (params) => {
    let bedRoomsUrls = [];
    let _searchType = params.searchType || _SEARCH_TYPES.SALES;
    let _area = params.area || '';
    _area = getArea(_area);
    _area = CheckArea(_area);
    let _bedRooms = params.bedRooms || _BED_ROOMS_NUMBERS;
    let _urlType = _SALES;
    switch (_searchType) {
        case _SEARCH_TYPES.SALES:
            _urlType = _SALES;
        break;
        case _SEARCH_TYPES.RENTALS:
            _urlType = _RENTALS;
        break;
    }
    // console.log('_urlType : ' + _urlType);
    _bedRooms.map((bed) => {
      let _bedRoomsUrls = {};
      let state = populateSearchState({areaAlias: _area.alias});
      state.range.bedroom.min = bed;
      // console.log(state);
      let data = populateSearchData({areaName: _area.name, pType: _searchType});
      data.bedrooms = bed;
      // console.log(data);
      _bedRoomsUrls.name = propertyH1(data);
      _bedRoomsUrls.alias = _urlType.createURL({}, state);
      let urlPathName = getUrlPathName();
      if ( urlPathName && (urlPathName === _bedRoomsUrls.alias) ) { // if same page url ...
        // ... ignore it
      } else {
        bedRoomsUrls.push(_bedRoomsUrls);
      }
    });
    return bedRoomsUrls;
}