import React from 'react';
import classNames from 'classnames';

/**
 * Context API
 */
import { useGlobalState } from '@store/hooks';

/**
 * Blocks
 */
import PropertyForm from '@blocks/property-form/property-form';
import SearchFormEdit from '@blocks/search-form-edit/search-form-edit';

/** 
 * Components
 */
import Modal from '@components/modal/modal';

const Modals = ({ className }) => {
  const { state, services } = useGlobalState('modals');
  const handleClose = (name, active) => services.modalToggle({name, active})
  return (
    <div className={classNames('modals', className)}>
      <Modal active={state.property_search} onClose={() => handleClose('property_search', false)}>
        <PropertyForm />
      </Modal>
      <Modal active={state.edit_search} onClose={() => handleClose('edit_search', false)}>
        <SearchFormEdit />
      </Modal>
    </div>
  )
}

export default Modals;
